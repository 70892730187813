import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import AvorPage from './AvorPage';
import AvorInstructions from './AvorInstructions';
import CyberProPage from './CyberProPage';
import CyberProFAQ from './CyberProFAQ';
import CyberProSupport from './CyberProSupport';
import AntiVorAntiVirus from './AntiVorAntiVirus';
import AvorDownloadUnisafe from './AvorDownloadUnisafe';
import AvorDownloadUsafe from './AvorDownloadUsafe';
import AvorDownloadProtect from './AvorDownloadProtect';
import Cyber from './Cyber';
import CreatePage from "./CreatePage";
import ProtectionStation from "./ProtectionStationPage";
import BuyPage from "./BuyPage";
import ProtectionStationRedirect from "./ProtectionStationRedirect";
import './i18n';
import AvorPageForMegafon from "./AvorPageForMegafon";
import CyberProPageForMegafon from "./CyberProPageForMegafon";


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/AntiThief" element={<AvorPage/>} />
                    <Route path="/AntiThiefDownload" element={<AvorPageForMegafon/>} />
                    <Route path="/buy/" element={<BuyPage/>} />
                    <Route path="/instructions/" element={<AvorInstructions/>} />
                    <Route path="/CyberProtection" element={<CyberProPage/>} />
                    <Route path="/CyberProtectionDownload" element={<CyberProPageForMegafon/>} />
                    <Route path="/CyberProtectionFAQ" element={<CyberProFAQ/>} />
                    <Route path="/CyberProtectionSupport" element={<CyberProSupport/>} />
                    <Route path="/AntiVorAntiVirus" element={<AntiVorAntiVirus/>}/>
                    <Route path="/AvorDownloadUnisafe" element={<AvorDownloadUnisafe/>}/>
                    <Route path="/AvorDownloadUsafe" element={<AvorDownloadUsafe/>}/>
                    <Route path="/AvorDownloadProtect" element={<AvorDownloadProtect/>}/>
                    <Route path="/Cyber" element={<Cyber/>}/>
                    <Route path="/ProtectionStation" element={<ProtectionStation/>}/>
                    <Route path="/services/protection-station/" element={<ProtectionStationRedirect/>} />
                    <Route path="/product" element={<CreatePage/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
